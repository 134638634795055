export default {
  constants: {
    IDENTIFIER_TYPE: {
      USER_ID: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
        name: "ID",
      },
      EMAIL: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
        name: "E-mail",
      },
      PHONE: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
        name: "Phone",
      },
      LOGIN: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e74",
        name: "Login",
      },
      FACEBOOK: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e84",
        name: "Facebook",
      },
      GOOGLE: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e85",
        name: "Google",
      },
      LINKEDIN: {
        id: "54480ce1-00eb-4179-a2b6-f74daa6b9e86",
        name: "LinkedIn",
      },
    },
    TARIFF: {
      LEADER: "597f4ca0-25cd-434c-b0e8-8d2d16a7d454",
      ANALYST: "9ba0f289-e8d8-4060-89f4-517274393c9c",
      CONSULTANT: "07053c66-377e-4cb4-9b97-ffc4e08f36c0",
      LEADER_YEARLY: "fea093e7-6e15-4080-969d-aaac7b8678b5",
      ANALYST_YEARLY: "79757f1e-040e-4e0c-943b-180ca3308949",
      CONSULTANT_YEARLY: "25c4301e-ab6a-4ab3-bf30-abc341a957b8",
    },
    SECTION_TYPE: {
      NEWS: "1eb9efe2-1428-476a-a69c-46c1cebb9dab",
      PAGES: "1eb9efe2-1428-476a-a69c-46c1cebb9113",
    },
    ELEMENT_FIELD_TYPES: {
      SINGLE_LINE_TEXT: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      MULTI_LINE_TEXT: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
      CHECK_BOX: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
      NUMBER: "54480ce1-00eb-4179-a2b6-f74daa6b9e74",
      STORED_FILE: "54480ce1-00eb-4179-a2b6-f74daa6b9e75",
    },
    STORE_MODE_TYPES: {
      LOCAL: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
    },
    ELEMENT_SECTION_TYPES: {
      BLOG_RU: "1eb9efe2-1428-476a-a69c-46c1cebb9da1",
      BLOG_EN: "1eb9efe2-1428-476a-a69c-46c1cebb9da2",
      BLOG_ES: "1eb9efe2-1428-476a-a69c-46c1cebb9da3",
      PAGES: "1eb9efe2-1428-476a-a69c-46c1cebb9113",
    },
    DISCOUNT_TYPES: {
      FIX_AMOUNT_DISCOUNT: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      FIX_PERCENT_DISCOUNT: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
      GIFT_PRODUCT: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
    },

    NOTIFICATION_STATES: {
      DELIVERED: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
    },

    FINANCIAL_DOCUMENT_TYPES: {
      PAYMENT_INVOICE: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      TRANSFER_ACCEPTANCE_CERTIFICATE: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
      INVOICE: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
    },

    NOTIFICATION_RULE_TYPES: {
      DEVICE: "26984710-8b2b-4b01-beba-5bd572013760",
      PARAMETER: "26984710-8b2b-4b01-beba-5bd572013761",
    },
    ORDER_STATES: {
      DRAFT: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      PENDING: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
      PAID: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
      CANCELLED: "54480ce1-00eb-4179-a2b6-f74daa6b9e74",
    },
    OWNERSHIP_TYPES: {
      FULL: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      SUBSCRIPTION: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
    },
    REPORT_PART_TYPES: {
      MESSAGE: "abb82975-3e6c-423c-8c09-f21baf8c4242",
      MEASURE: "abb82975-3e6c-423c-8c09-f21baf8c4243",
    },
    PERMISSIONS: {
      READ: "7cbaf596-b644-40be-9e99-6c5e15304ae4",
      WRITE: "7cbaf596-b644-40be-9e99-6c5e15304ae5",
      MANAGE: "7cbaf596-b644-40be-9e99-6c5e15304ae6",
    },
    TEAM_TYPES: {
      PRIVATE_PERSON: "3a040868-552a-405a-93ba-9caf6fccbe37",
      CORPORATE: "3a040868-552a-405a-93ba-9caf6fccbe38",
    },
    PAYMENT_STATES: {
      CREATED: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      ACTIVATED: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
      CANCELED: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
    },
    PAYMENT_SYSTEMS: {
      YANDEX_KASSA: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      PAYPAL: "54480ce1-00eb-4179-a2b6-f74daa6b9e79",
      BRAINTREE: "54480ce1-00eb-4179-a2b6-f74daa6b9e78",
      ALFA_BANK: "54480ce1-00eb-4179-a2b6-f74daa6b9e80",
    },
    CURRENCIES: {
      BONUS: "54480ce1-00eb-4179-a2b6-f74daa6b9e71",
      USD: "54480ce1-00eb-4179-a2b6-f74daa6b9e72",
      RUB: "54480ce1-00eb-4179-a2b6-f74daa6b9e73",
      EUR: "54480ce1-00eb-4179-a2b6-f74daa6b9e74",
    },
    USER_RIGHTS: {
      Monitoring: "DEVICE:SHOW",
      Data_shop: "DEVICE:SHOW",
      Pricing: "DEVICE:SHOW",

      Dashboard: "DASHBOARD:SHOW",

      // DevicesР
      Devices: "DEVICE:SHOW",
      Catalogues: "CATALOGUE:SHOW",
      Messages: "MESSAGE:SHOW",
      Parameters: "PARAMETER:SHOW",
      Reports: "SYSTEM:ADMIN",

      // Management
      Teams: "TEAM:SHOW",
      Device_types: "DEVICE_TYPE:SHOW",
      Data_packages: "DATA_PACKAGE:SHOW",
      ML_Models: "ML_MODEL:SHOW",
      Units: "UNIT:SHOW",
      Eco: "DEVICE:SHOW",
      Meteo: "DEVICE:SHOW",
      Line_monitoring: "DEVICE:SHOW",
      SKAT_VOMP: "DEVICE:SHOW",
      SKAT_GIO: "DEVICE:SHOW",
      Powerline: "DEVICE:SHOW",
      SCAT_devices: "DEVICE:SHOW",

      // Content
      BlogRu: "ELEMENT:SAVE", // заглуша
      BlogEn: "ELEMENT:SAVE", // заглуша
      BlogEs: "ELEMENT:SAVE", // заглуша

      // Finance
      Accounts: "ACCOUNT:SHOW",
      Transactions: "TRANSACTION:SHOW",
      Payments: "PAYMENT:SHOW",
      Products: "PRODUCT:SHOW",
      Discounts: "DISCOUNT:SHOW",
      Orders: "ORDER:SHOW",
      Tariffs: "TARIFF:SHOW",

      // Administration
      Users: "USER:SHOW",
      Roles: "ROLE:SHOW",
      Tokens: "TOKEN:SHOW",
      Properties: "PROPERTY:SHOW",
      Templates: "TEMPLATE:SHOW",
      Notifications: "NOTIFICATION:SHOW",
      Tasks: "TASK:SHOW",
      Feedbacks: "FEEDBACK:SHOW",
      Passphrase_types: "PASSPHRASE_TYPE:SHOW",

      // Documentation
      Api: "API:SHOW",
      Documentation_import: "API:SHOW",

     Logs: "SYSTEM:ADMIN"
    },
    DEFAULT_LOGO:
      "https://storage.yandexcloud.net/nlg/fb79597d-6a73-42d3-aa62-6c2f7a049901",
  },
  userHasGrantForMenu: function (user, items) {
    for (var i = 0; i < items.length; i++) {
      if (items[i].submenu) {
        var hasRightForSubMenu = false;
        for (var j = 0; j < items[i].submenu.length; j++) {
          if (
            !user.grantsSet.has(
              this.constants.USER_RIGHTS[items[i].submenu[j].code]
            )
          ) {
            items[i].submenu.splice(j, 1);
            j--;
          } else {
            hasRightForSubMenu = true;
          }
        }
        if (!hasRightForSubMenu) {
          items.splice(i, 1);
          i--;
        }
      } else {
        if (!user.grantsSet.has(this.constants.USER_RIGHTS[items[i].code])) {
          items.splice(i, 1);
          i--;
        }
      }
    }
    return items;
  },
  dateFormatter(value, format = "datetime") {
    const options = {};

    if (format.includes("date")) {
      options.day = "2-digit";
      options.month = "short";
      options.year = "numeric";
    }

    if (format.includes("time")) {
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.second = "2-digit";
    }
    return new Intl.DateTimeFormat(
      localStorage.getItem("language") || "en",
      options
    ).format(new Date(value));
  },
};
